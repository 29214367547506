<template>
  <div class="sc-dv-data sc-dv-person">
    <!-- 男性数据 -->
    <div v-if="man" class="sub man">
      <div class="icon">
        <img src="/images/2020/icon-man.svg" />
      </div>
      <div class="data">
        <div class="base">
          <span>{{ man }}</span>
          <em v-if="suffix">{{ suffix }}</em>
        </div>
        <div v-if="manRatio" class="sum">{{ manRatio }}%</div>
      </div>
    </div>
    <!-- 女性数据 -->
    <div v-if="woman" class="sub woman">
      <div class="icon">
        <img src="/images/2020/icon-woman.svg" />
      </div>
      <div class="data">
        <div class="base">
          <span>{{ woman }}</span>
          <em v-if="suffix">{{ suffix }}</em>
        </div>
        <div v-if="womanRatio" class="sum">{{ womanRatio }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Person',
  props: {
    // 男性数据
    man: [String, Number],

    // 男性占比
    manRatio: [String, Number],

    // 女性数据
    woman: [String, Number],

    // 女性占比
    womanRatio: [String, Number],

    // 数据后缀
    suffix: String,
  },
};
</script>

<style lang="scss" scoped>
$void: 10px;

// 参数
$suffixColor: #a8addc;
$baseColor: #fff;
$sumManColor: #44d7b6;
$sumWomanColor: #fa5d8f;
$iconSize: 5rem;

.sc-dv-person {
  align-items: center;
  display: flex;
  justify-content: space-around;
  position: relative;
  height: 100%;
  width: 100%;

  .sub {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;

    // 图表
    .icon {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
      margin-bottom: $void;
      height: $iconSize;
      width: $iconSize;

      img {
        max-width: 100%;
      }
    }

    // 数据
    .data {
      position: relative;
      text-align: center;

      .base {
        align-items: flex-end;
        display: flex;
        justify-content: center;

        span,
        em {
          display: block;
          font-style: normal;
          line-height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          font-family: 'DIN Alternate Bold';
          color: $baseColor;
          font-size: 1.3rem;
          text-align: right;
        }

        em {
          color: $suffixColor;
          flex-shrink: 0;
          font-size: 0.8rem;
          margin-left: 8px;
        }
      }

      .sum {
        font-family: 'DIN Alternate Bold';
        font-size: 24px;
        line-height: 32px;
      }
    }

    // 颜色区分
    &.man .sum {
      color: $sumManColor;
    }
    &.woman .sum {
      color: $sumWomanColor;
    }
  }
}
</style>
