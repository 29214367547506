<template>
  <div class="node">
    <!-- 数据流转特效 -->
    <div class="process">
      <i />
      <i />
      <i />
    </div>
    <!-- 节点 -->
    <div class="base">
      <div class="title">{{ node.title }}</div>
      <div class="data">
        <span>
          <animated-number
            :value="node.sum"
            :formatValue="formatDefault"
            :duration="1000"
            easing="easeOutQuad"
          />
        </span>
        <em>笔</em>
      </div>
    </div>
    <!-- 扩散气泡 -->
    <div class="bubble"></div>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue';

export default {
  name: 'SCDVStatusProcessStyle0001Node',
  components: {
    AnimatedNumber,
  },
  props: {
    node: {
      type: [Object, String],
      default: function () {
        return null;
      },
    },
  },
  methods: {
    handleProcessEnd(e) {
      e.target.parentNode.appendChild(document.createElement('i'));
      e.target.parentNode.appendChild(document.createElement('i'));
      e.target.parentNode.appendChild(document.createElement('i'));
      e.target.parentNode.removeChild(e.target);
    },

    formatDefault(val) {
      return parseInt(val, 10) || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
// 节点大小
$nodeSize: 86px;
// 外边距
$offset: 20px;

.sc-dv-status-process > .node {
  box-sizing: content-box;
  flex-shrink: 0;
  line-height: 0;
  height: $nodeSize;
  padding: 0 $offset;
  position: relative;
  width: $nodeSize;

  // 连接线
  & > .process {
    background-image: linear-gradient(to right, #3d387d 0%, #4eccce 100%);
    position: absolute;
    left: -($offset + 6);
    top: 50%;
    margin-top: -2px;
    height: 4px;
    width: ($offset * 2) + 14;
    z-index: 1;

    i {
      background: url('/images/2020/status-process-style-001-node-dot.svg')
        no-repeat center;
      height: 10px;
      left: 0;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      width: 6px;

      &:nth-child(1) {
        animation: newOrder 1.2s linear infinite;
      }
      &:nth-child(2) {
        animation: newOrder 1.2s 0.5s linear infinite;
      }
      &:nth-child(3) {
        animation: newOrder 1.2s 1s linear infinite;
      }
    }
  }

  &:first-child .process {
    display: none;
  }

  // 节点
  .base {
    align-items: center;
    background: url('/images/2020/status-process-style-001-node.svg') no-repeat
      center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $nodeSize;
    position: relative;
    width: $nodeSize;
    z-index: 3;

    .title {
      font-size: 1rem;
      line-height: 20px;
    }

    .data {
      align-items: center;
      display: flex;
      justify-content: center;
      line-height: 24px;

      span,
      em {
        display: block;
        font-style: normal;
        padding: 0 2px;
        overflow: hidden;
      }

      span {
        font-family: 'DIN Alternate Bold';
        font-size: 1.2rem;
        font-weight: bold;
      }

      em {
        font-size: 0.8rem;
      }
    }
  }

  // 波纹扩散
  .bubble {
    animation: bubble 3s ease-out infinite;
    transform: scale(0.5);
    opacity: 0;
    z-index: 2;

    &,
    &::before,
    &::after {
      background-color: rgba(140, 132, 206, 0.3);
      border: 2px #00fff5 dotted;
      border-radius: 50%;
      height: $nodeSize;
      top: 50%;
      margin: -($nodeSize / 2);
      left: 50%;

      position: absolute;
      width: $nodeSize;
    }

    &::before,
    &::after {
      content: '';
    }

    &::before {
      opacity: 0.6;
      transform: scale(1.2);
    }

    &::after {
      opacity: 0.2;
      transform: scale(1.5);
    }
  }
}

// 流程节点流入
@keyframes newOrder {
  0%,
  70% {
    opacity: 1;
  }
  100% {
    transform: translate3d(($offset * 2) + 14, 0, 0);
    opacity: 0;
  }
}

// 波纹扩散
@keyframes bubble {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  40% {
    opacity: 0.8;
  }
  to {
    opacity: 0;
    transform: scale(1.2);
  }
}
</style>
