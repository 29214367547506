<template>
  <div class="sc-dv-ratio-box" :style="style">
    <div class="inner">
      <slot />
    </div>
  </div>
</template>

<script>
// 比例缩放组件
// 根据给定的比例，动态适应调整出一个区域
export default {
  name: 'RatioBox',
  props: {
    // 比例 :: 取值范围 0 - 1
    // 示例：<RatioBox :ratio="9/16" />
    ratio: {
      type: Number,
      default: 1,
      validator: function (value) {
        if (isNaN(+value)) return false;
        return 0 <= value && value <= 1;
      },
    },
  },
  computed: {
    style: function () {
      const { ratio } = this.$props;
      return {
        paddingBottom: `${ratio * 100}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sc-dv-ratio-box {
  flex: 1;
  height: 0;
  position: relative;

  & > .inner {
    left: 0;
    top: 0;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
