<template>
  <div :class="['video-wrap', active ? 'active' : '']">
    <transition
      enter-active-class="animate_mask_in"
      leave-active-class="animate_mask_out"
    >
      <div v-show="active" class="mask"></div>
    </transition>
    <transition
      enter-active-class="animate_video_in"
      leave-active-class="animate_video_out"
    >
      <div v-show="active" class="video-box">
        <div class="video">
          <div class="inner">
            <video
              @play="handlePlay"
              @ended="handleEnded"
              ref="video"
              :src="videoFile"
              :autoplay="autoplay"
              preload="auto"
              controls
              muted
            />
            <button class="close" @click="close">关闭</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const moment = window.moment || function () {};

/**
 * 视频播放器
 * @description 定时播放时视频
 */
export default {
  name: "Video",
  props: {
    // 启用状态
    enable: Boolean,

    // 播放间隔，单位毫秒，默认为 30 分钟
    interval: {
      type: Number,
      default: 1000 * 60 * 30
    },

    // 是否静音
    mute: {
      type: Boolean,
      default: false
    },

    // 是否区间时段静音
    muteTimeInterval: {
      type: Boolean,
      default: false
    },

    // 静音区间时段
    // 如：['00:00-08:00', '09:00-12:00','13:00-18:00','19:00-00:00']
    muteTimeIntervalOptions: {
      type: Array,
      default() {
        return ["00:00-08:59", "09:00-12:59", "13:00-18:59", "19:00-23:59"];
      }
    },

    // 是否全屏
    fullscreen: {
      type: Boolean,
      default: false
    },

    // 播放开始前执行的回调
    beforePlay: {
      type: Function
    },

    // 播放结束时执行的回调
    endPlay: {
      type: Function
    },

    autoplay: Boolean
  },
  data() {
    return {
      videoFile:
        process.env.NODE_ENV === "development"
          ? "/202011061700-aec7080988294f23b59dc8f115d2188f.mp4"
          : "https://localhost/202011061700-aec7080988294f23b59dc8f115d2188f.mp4",
      active: false, // 是否显示窗口
      timer: null,
      playing: false, // 是否正在播放
      state: this.$props.enable ?? false, // 启用状态
      timeIntervalTimer: null // 区间时间轮询
    };
  },
  computed: {
    // 是否设置有有效的时间
    canDountDown() {
      const { interval } = this.$props;
      if (!interval) return false;
      return interval > 0;
    }
  },
  watch: {
    // 播放间隔发生变化时，重新开始计时
    interval() {
      this.stopTimer();
      this.startTimer();
    },

    // 显隐状态
    active(opended) {
      opended ? this.handleStart() : this.handleStop();
    },

    // 启用状态切换
    enable(isOn) {
      this.state = isOn;
    },

    // 启用状态切换
    state(isOn) {
      isOn ? this.startTimer() : this.stopTimer();
      this.$emit("on-state-toggle", isOn);
    },

    // 静音切换
    mute(isMute) {
      const { video } = this.$refs.video;
      video.volume = isMute ? 0 : 1;
    }
  },
  methods: {
    // 初始化
    init() {
      const { video } = this.$refs;
      const { mute, autoplay } = this.$props;
      if (mute) {
        video.volume = 0;
      }
      if (autoplay) {
        // 等待主屏幕图表就绪，否则，大量的动效会导致页面严重卡顿
        setTimeout(this.open, 1000 * 5);
      } else {
        this.startTimer();
        this.startTimeIntervalMuteCheck();
      }
    },

    // 开始播放
    handleStart() {
      const { video } = this.$refs;
      video.play();
    },

    // 暂停播放
    handlePause() {
      const { video } = this.$refs;
      if (video.paused) return;
      video.pause();
      this.playing = false;
    },

    // 停止播放
    handleStop() {
      const { video } = this.$refs;
      video.pause();
      video.currentTime = 0;
      this.playing = false;
    },

    // 开始播放
    handlePlay() {
      const { video } = this.$refs;
      this.stopTimer();
      this.playing = true;

      if (this.$props.fullscreen) {
        video.requestFullscreen();
      }

      this.$emit("on-play", this);
    },

    // 播放结束
    handleEnded() {
      this.playing = false;
      if (this.$props.fullscreen) {
        document.exitFullscreen();
      }
      this.close();
      this.$emit("on-ended", this);
    },

    // 播放或暂停播放
    toggle() {
      const { video } = this.$refs;
      const { active } = this;

      // 如果窗口未显示，则显示窗口
      if (!active) {
        this.open();
      }

      video.paused ? this.handleStart() : this.handlePause();
    },

    // 打开视频并播放
    open() {
      this.active = true;
      this.stopTimer();
      this.handleStart();
      this.$emit("on-open", this);
    },

    // 关闭播放窗口
    close() {
      this.active = false;
      this.handleStop();
      this.startTimer();
      this.$emit("on-close", this);
    },

    // 开始计时
    startTimer() {
      const { canDountDown } = this;
      if (!canDountDown) return;

      const { interval } = this.$props;

      // 延迟指定时间后播放
      this.timer = setTimeout(this.open, interval);
    },

    // 停止计时
    stopTimer() {
      // if (!this.timer) return;
      // console.log('hangup...');
      this.timer = clearTimeout(this.timer);
    },

    // 计时状态切换
    toggleTimer() {},

    // 判断区间时段静音
    checkTimeIntervalMute() {
      const { muteTimeInterval } = this.$props;
      if (!muteTimeInterval) return;

      const { video } = this.$refs;

      // 获取但当前时间戳
      const now = new Date().getTime();
      const today = moment().format("YYYY-MM-DD");

      // 根据指定的值，创建时间区间时间戳
      const { muteTimeIntervalOptions } = this.$props;
      const refers = [...muteTimeIntervalOptions].map((set) => {
        return set
          .split("-")
          .map((part, partIndex) =>
            new Date(`${today} ${part}:${["00", "59"][partIndex]}`).getTime()
          );
      });

      // 验证当前时间
      const inRange = [...refers].reduce((flag, set) => {
        flag = flag || (now >= set[0] && now <= set[1]);
        return flag;
      }, false);

      // 调整音量
      video.volume = inRange ? 0 : 1;
    },

    // 开始区间时段静音轮询
    startTimeIntervalMuteCheck() {
      const { muteTimeInterval } = this.$props;
      if (!muteTimeInterval || this.timeIntervalTimer) return;
      this.timeIntervalTimer = setInterval(this.checkTimeIntervalMute, 1000);
    },

    // 停止区间时段静音轮询
    stopTimeIntervalMuteCheck() {
      if (!this.timeIntervalTimer) return;
      this.timeIntervalTimer = clearInterval(this.timeIntervalTimer);
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.stopTimer();
    this.stopTimeIntervalMuteCheck();
  }
};
</script>

<style lang="scss" scoped>
.video-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9999;
}

.active {
  pointer-events: auto !important;
}

.video-box {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  max-width: 100%;
  margin: 0 auto;
  perspective: 1000vw;
  position: relative;
  transform: scale(1.2);
  z-index: 2;
}

.mask {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.video {
  background-color: #000;
  flex: 1;
  padding-bottom: percentage(0.41666);
  height: 0;
  position: relative;
  transform-style: preserve-3d;
  transform: scaleX(0.7) !important;

  .inner {
    border-radius: 8px;
    box-shadow: 0 32px 64px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  video {
    height: 100%;
    outline: 0;
    width: 100%;
  }

  .close {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    color: #fff;
    cursor: pointer;
    outline: none;
    opacity: 0;
    padding: 4px 8px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
  }

  &:hover {
    .close {
      opacity: 1;
    }
  }
}

.animate_mask_in {
  animation: maskIn 1.2s ease;
}

.animate_mask_out {
  animation: maskOut 1.2s ease 0.6s;
}

.animate_video_in {
  animation: videoIn 1.2s ease;
}

.animate_video_out {
  animation: videoOut 1s ease;
}

// 遮罩进入
@keyframes maskIn {
  from {
    opacity: 0;
  }
}

// 遮罩退出
@keyframes maskOut {
  to {
    opacity: 0;
  }
}

// 视频进入
@keyframes videoIn {
  from {
    transform: scale(1) rotate3d(1.5, 2, 0, 45deg);
    opacity: 0;
  }
}

// 视频退出
@keyframes videoOut {
  to {
    transform: scale(0) translateY(100%);
    opacity: 0;
  }
}
</style>
