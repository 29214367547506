import { GetApplyTrend } from "@/api/Reception2020";

// import TimerTask from '@/utils/timerTask';

const echarts = window.echarts || function () {};

// 融资回款趋势
export default {
  data() {
    return {
      financeTrendPending: false, // 加载指示
      financeTrendTask: null, // 定时刷新任务
      financeTrendTaskDuration: 1000 * 60, // 定时任务执行间隔
      financeTrend: null, // 融资回款趋势
      financeTrendChart: null, // echart 实例
      financeTrendChartResize: null, // 图表尺寸调整防抖控制
      financeTrendChartCurrent: -1, // 高亮索引
      financeTrendChartAutoPlay: null, // 高亮播放定时器
      financeTrendChartAutoPlayDuration: 1000 * 5 // 高亮切换间隔
    };
  },
  methods: {
    // 加载融资回款趋势数据
    async loadFinanceTrend() {
      try {
        this.stopFinanceTrendChartPlay();
        this.cancelFinanceTrendChartEvent();
        this.financeTrendPending = true;
        this.financeTrend = await GetApplyTrend();
        this.$nextTick(() => {
          this.initFinanceTrendChart(this.parseFinanceTrendChartOptions());
        });
        // setTimeout(() => {
        //   this.initFinanceTrendChart(this.parseFinanceTrendChartOptions());
        // }, 200);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.financeTrendPending = false;
      }
    },

    // 构造图表数据
    parseFinanceTrendChartOptions() {
      const { financeTrend } = this;

      const dataA = [...financeTrend].map((row) => +row.amount || 0);
      const dataB = [...financeTrend].map((row) => +row.payment || 0);

      const max = Math.max(...dataA, ...dataB) * 1.2;
      const interval = Math.ceil(max / 4);

      return {
        grid: {
          left: "15%",
          top: "28%",
          bottom: "15%",
          right: "15%"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          backgroundColor: "rgba(54, 85, 219, 0.8)",
          borderRadius: 3,
          borderColor: "rgba(81, 107, 145, 0)",
          borderWidth: 0,
          padding: [4, 6],
          textStyle: {
            color: "#fff",
            fontSize: 12
          },
          shadowStyle: {
            color: "rgba(50,197,255,0.1)"
          },
          formatter: (params) => {
            const { amount } = this.$options.filters;
            const c0 = amount(parseInt(params[0].data, 10), {
              fixed: 0
            });
            const c1 = amount(parseInt(params[1].data, 10), {
              fixed: 0
            });
            return `融资 ${c0} 元，回款 ${c1} 元`;
          },
          position: function (point, params, dom, rect, size) {
            const _pos = {
              left: point[0] - dom.clientWidth / 2,
              top: 35
            };

            // 如果在左侧，则优先靠左展示
            if (point[0] < size.viewSize[0] / 4) {
              _pos["left"] = 10;
            }

            // 如果在最右侧，则优先靠右展示
            if (point[0] > size.viewSize[0] - size.viewSize[0] / 4) {
              _pos["left"] = size.viewSize[0] - dom.clientWidth - 10;
            }

            return _pos;
          }
        },
        legend: {
          show: true,
          orient: "horizontal",
          right: "5%",
          top: 8,
          itemHeight: 4,
          itemWidth: 16,
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          type: "category",
          data: [...financeTrend].map((row) =>
            ("0" + row.showMonths).slice(-3)
          ),
          interval: 0,
          axisTick: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.3)",
              width: 2
            }
          }
        },
        yAxis: [
          {
            type: "value",
            min: 0,
            max: interval * 4,
            interval,
            splitLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.3)",
                type: "dotted"
              }
            },
            axisLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.3)",
                width: 2
              }
            },
            axisTick: { inside: true },
            axisLabel: {
              color: "#fff",
              formatter: (params) => {
                return `${parseInt(params / 10000000, 10)} 千万`;
              }
            }
          },
          {
            type: "value",
            min: 0,
            max: interval * 4,
            interval,
            splitLine: { show: false },
            axisTick: { inside: true },
            axisLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.3)",
                width: 2
              }
            },
            axisLabel: {
              color: "#fff",
              formatter: (params) => {
                return `${parseInt(params / 10000000, 10)} 千万`;
              }
            }
          }
        ],
        series: [
          {
            type: "bar",
            name: "融资",
            data: dataA,
            barWidth: "30%",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#76D8FF"
                  },
                  {
                    offset: 1,
                    color: "#365AE0"
                  }
                ],
                false
              ),
              barBorderRadius: [3, 3, 0, 0]
            }
          },
          {
            type: "bar",
            name: "回款",
            data: dataB,
            barWidth: "30%",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#00d8d6"
                    },
                    {
                      offset: 1,
                      color: "#0be881"
                    }
                  ],
                  false
                ),
                barBorderRadius: [3, 3, 0, 0]
              }
            },
            yAxisIndex: 1,
            showSymbol: false,
            symbolSize: 4
          }
        ],
        animationDelay: (index) => index * 60,
        animationDuration: 600,
        animationEasing: "backOut"
      };
    },

    // 创建融资回款趋势图表
    initFinanceTrendChart(options) {
      // 初始化图表
      if (
        this.$refs &&
        this.$refs.financeTrendChart &&
        !this.financeTrendChart
      ) {
        this.financeTrendChart = echarts.init(this.$refs.financeTrendChart);
      }
      if (this.financeTrendChart) {
        // 装载图表参数
        this.financeTrendChart.setOption(options, true);

        // 启动自动高亮播放
        this.startFinanceTrendChartPlay();

        // 添加事件绑定
        this.startFinanceTrendChartEvent();
      }
    },

    // 调整图表尺寸
    handleFinanceTrendChartResize() {
      if (this.financeTrendChartResize) {
        this.financeTrendChartResize = clearTimeout(
          this.financeTrendChartResize
        );
      }
      this.financeTrendChartResize = setTimeout(() => {
        this.financeTrendChart.resize();
        this.financeTrendChartResize = clearTimeout(
          this.financeTrendChartResize
        );
      }, 100);
    },

    // 开始自动播放
    startFinanceTrendChartPlay() {
      this.financeTrendChartCurrent += 1;
      if (this.financeTrendChartCurrent >= this.financeTrend.length) {
        this.financeTrendChartCurrent = 0;
      }
      this.handleFinanceTrendChartToolTips();
      this.financeTrendChartAutoPlay = setTimeout(() => {
        this.startFinanceTrendChartPlay();
      }, this.financeTrendChartAutoPlayDuration);
    },

    // 停止自动播放
    stopFinanceTrendChartPlay() {
      this.financeTrendChartAutoPlay = clearTimeout(
        this.financeTrendChartAutoPlay
      );
    },

    // 播放方法
    handleFinanceTrendChartToolTips() {
      this.financeTrendChart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: this.financeTrendChartCurrent
      });
    },

    // 开始事件监听
    startFinanceTrendChartEvent() {
      if (this.$refs.financeTrendChart) {
        this.$refs.financeTrendChart.addEventListener(
          "mouseenter",
          this.stopFinanceTrendChartPlay
        );
        this.$refs.financeTrendChart.addEventListener(
          "mouseleave",
          this.startFinanceTrendChartPlay
        );
      }
      window.addEventListener("resize", this.handleFinanceTrendChartResize);
    },

    // 移除事件监听
    cancelFinanceTrendChartEvent() {
      if (this.$refs.financeTrendChart) {
        this.$refs.financeTrendChart.removeEventListener(
          "mouseenter",
          this.stopFinanceTrendChartPlay
        );

        this.$refs.financeTrendChart.removeEventListener(
          "mouselevel",
          this.startFinanceTrendChartPlay
        );
      }

      window.removeEventListener("resize", this.handleFinanceTrendChartResize);
    }
  },
  // mounted() {
  //   // this.financeTrendTask = new TimerTask({
  //   //   task: this.loadFinanceTrend,
  //   //   duration: 1000 * 60,
  //   // });
  //   console.log("financeTrend - mounted");
  //   this.loadFinanceTrend();
  // },
  beforeDestroy() {
    // 结束定时任务
    this.financeTrendTask.dispose();
    // 结束自动播放
    this.stopFinanceTrendChartPlay();
    // 移除事件监听
    this.cancelFinanceTrendChartEvent();
  }
};
