<template>
  <div class="sc-dv-counter">
    <div class="title-wrap" v-if="title">
      <div class="title">
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="params">
      <div class="param">
        <span>{{ base || '-' }}</span>
        <em v-if="baseSuffix">{{ baseSuffix }}</em>
      </div>
      <div class="separator" v-if="separator">{{ separator }}</div>
      <div class="param" v-if="showSub">
        <span>{{ sub || '-' }}</span>
        <em v-if="subSuffix">{{ subSuffix }}</em>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataCounterRound',
  props: {
    // 标题
    title: String,

    // 主要参数
    base: [String, Number],

    // 主要参数后缀
    baseSuffix: String,

    // 次要参数
    sub: [String, Number],

    // 次要参数后缀
    subSuffix: String,

    // 分隔符
    separator: {
      type: [String, Boolean],
      default: '/',
    },

    // 是否使用 sub
    showSub: Boolean,
  },
};
</script>

<style lang="scss" scoped>
$void: 10px;

// 标题装饰
$titleAdornColor: #00fff5;
$titleAdornSize: 8px;
$titleAdornWidth: 2px;

// 参数
$suffixColor: #a8addc;
$baseColor: #fff;

.sc-dv-counter {
  padding: $void;
  position: relative;

  // 标题
  .title {
    margin-bottom: 5px;
    position: relative;

    &-wrap {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;
    }

    span {
      background-image: linear-gradient(to right top, #4eccce, #3d387d);
      display: inline-block;
      border-radius: 24px;
      font-size: 0.8rem;
      padding: 0 8px;
      height: 22px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // 参数
  .params {
    align-items: center;
    display: flex;
    justify-content: center;

    .param {
      align-items: flex-end;
      display: flex;
      justify-content: center;

      span,
      em {
        display: block;
        font-style: normal;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span {
        font-family: 'DIN Alternate Bold';
        color: $baseColor;
        font-size: 1.2rem;
        text-align: right;
      }

      em {
        color: $suffixColor;
        flex-shrink: 0;
        font-size: 0.8rem;
        margin-left: 8px;
      }
    }
  }

  // 分隔符
  .separator {
    padding: 0 8px;
  }
}
</style>
