import { GetApplyTranAreaAndAgent } from "@/api/Reception2020";

// import TimerTask from '@/utils/timerTask';

// 月度区域和服务商
export default {
  data() {
    return {
      tranAreaAndAgentPending: false, // 加载指示
      tranAreaAndAgentTask: null, // 定时刷新任务
      tranAreaAndAgentTaskDuration: 1000 * 60 * 5, // 定时任务间隔
      tranAreaAndAgent: [], // 月度区域成交排名
      tranAreaAndAgentColumns: [
        { title: "排名", key: "index", width: 60 },
        { title: "地区", key: "provinceName" },
        { title: "商户数量", key: "merchantNum", width: 80 },
        { title: "成交金额（元）", key: "financeAmt" },
        { title: "笔数", key: "orderNum", width: 60 }
      ],
      merchantAgenTotal: 0 // 在线经纪人活跃数
    };
  },
  methods: {
    // 加载成交区域排名
    async loadTranAreaAndAgent() {
      const { amount } = this.$options.filters;

      try {
        this.tranAreaAndAgentPending = true;

        const res = await GetApplyTranAreaAndAgent();

        this.merchantAgenTotal = +res.merchantAgentNum || 0;

        this.tranAreaAndAgent = [...res.list].map((row) => {
          return {
            ...row,
            financeAmt: amount(row.financeAmt, { fixed: 0 })
          };
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.tranAreaAndAgentPending = false;
      }
    }
  },
  // mounted() {
  //   // this.tranAreaAndAgentTask = new TimerTask({
  //   //   task: this.loadTranAreaAndAgent,
  //   //   duration: this.tranAreaAndAgentTaskDuration,
  //   // });
  //   console.log("tranAgent - mounted");
  //   this.loadTranAreaAndAgent();
  // },
  beforeDestroy() {
    this.tranAreaAndAgentTask.dispose();
  }
};
