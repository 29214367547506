<template>
  <div class="sc-dv-data sc-dv-status-process">
    <node v-for="(node, index) in renderNode" :key="index" :node="node" />
  </div>
</template>

<script>
import node from './node.vue';

// 订单实时流转
export default {
  name: 'SCDVStatusProcessStyle0001',
  components: {
    node,
  },
  props: {
    // 节点设置
    nodes: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    // 渲染节点
    renderNode: function () {
      const { nodes } = this.$props;
      if (!Array.isArray(nodes)) return [];
      return [...nodes].map((node) => {
        return {
          title: '',
          name: '',
          sum: 0,
          ...node,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sc-dv-status-process {
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
