// 汽车品牌处理

export const carBrandLogo = {
  大众: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/b4917b15-519d-4fb3-8184-709ffd8df3b8.jpg',
  本田: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/4c96ee54-1faa-4e06-a0d5-bbba2b825513.jpg',
  现代: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/26f0c3bd-12e7-4399-a930-32a2977c96ce.jpg',
  丰田: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/f89d01fe-1b75-4a55-8997-e78a405c167d.jpg',
  福特: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/f8bdb7e1-cbc2-4c56-a5db-ab141196be52.jpg',
  日产: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/05509161-309a-4def-b75c-84385fc2cadb.jpg',
  哈弗: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/c75f9057-e679-49f4-b06e-a5636cd8a06f.jpg',
  奥迪: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/62ff0f00-cbfd-4b67-b316-1a481fa22b1a.jpg',
  别克: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/bd0cac8f-2b51-4e8f-9f15-4408b0370726.jpg',
  长安: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/fcf701ff-c9c0-42b7-b11a-0f8de9d5d37e.jpg',
  起亚: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/321d67ce-bfd6-417f-b287-5d4d5092279f.jpg',
  吉利汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/af3fe664-1740-4b7e-b153-d8c694a06c37.jpg',
  宝马: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/db17381b-04b0-4baa-b52e-77c46176d640.jpg',
  奔驰: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/dbcee9ed-7188-4230-beec-0d5fdfb3ae23.jpg',
  雪佛兰:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/e027a091-3359-4f33-9d6f-be054f12b4d5.jpg',
  宝骏: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/97796e1f-22d1-4ae3-90cc-865bab9d4c94.jpg',
  广汽传祺:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/ba6fb1ea-4537-46a7-8033-5d6fc6c5168a.jpg',
  众泰: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/3c3f8a7a-087c-4a63-85f2-69ddbcd125d6.jpg',
  马自达:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/47bbd23b-e9f5-4b3d-b1ba-2b11313bbe54.jpg',
  五菱汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/bc9c024f-292d-4834-9939-0fc3c020e658.jpg',
  标致: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/b64dfb25-9a99-43f9-8813-11841bd88e73.jpg',
  荣威: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/79197e44-e8b2-4e95-9b3f-27ea6a52e148.jpg',
  长城: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/e7ffd683-6fb5-4d72-bd75-6af2fb77a7bf.jpg',
  凯迪拉克:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/6e5bf0d1-7283-49f2-8d5e-ca88b851348b.jpg',
  奇瑞: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/bf7f54c3-6656-4d74-83fd-c696f456ab06.jpg',
  比亚迪:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/190659cd-91ee-4208-8822-7a947479e835.jpg',
  jeep: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/e2c70222-1fa1-44a7-b08e-885c662e5b59.jpg',
  斯柯达:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/30ec3462-d5fa-422d-a749-b39c06d34c48.jpg',
  海马: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/4a56f44c-c502-4af8-92e7-b8b254b34955.jpg',
  江淮: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/3a200c28-b135-4818-b6b6-97e0853950d4.jpg',
  东风风光:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/2df028ef-7697-44bf-bb72-ab33d56a14e7.jpg',
  东风风行:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/55eddefa-8a9b-42fe-a876-12ca4b665f02.jpg',
  雪铁龙:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/0a98c3e6-90a8-4503-9e1d-48fe877b41f2.jpg',
  路虎: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/a4c09599-9979-46f8-8212-ca12b628a004.jpg',
  猎豹汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/428ec3c1-c0c8-43b9-9857-916b64da66c2.jpg',
  mg: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/3abfa413-b31b-46ca-ba39-962d992a01b0.jpg',
  北汽幻速:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/18029f89-c857-4923-a4c2-5e3d70eaceb4.jpg',
  三菱: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/974a7b1c-bdef-4387-81ff-dd3f60251840.jpg',
  雷克萨斯:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/25ec2e60-5e40-4401-846c-809c1eab2ceb.jpg',
  江铃: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/d11d0c3b-8777-4abd-a9d8-dc65a744cb48.jpg',
  奔腾: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/a69a89b3-95c7-42cb-9a4b-c6e3457587f7.jpg',
  长安商用:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/e7266fe4-cb55-4ef2-8ffd-f56f4b40c083.jpg',
  东风风神:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/f30600e0-a7ae-4b0d-a10a-b74dc4904e96.jpg',
  启辰: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/5b739b36-8d30-4782-8147-2c4d9715e7a6.jpg',
  东南: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/d27028d9-53af-4857-b635-d7d6e0474508.jpg',
  陆风: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/b589e958-ce4a-4460-bcbb-5825577bed4a.jpg',
  中华: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/eab16e01-1483-4e7d-9fb2-762bafeb6a94.jpg',
  纳智捷:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/e6247045-9546-4436-9748-22b4fee09436.jpg',
  保时捷:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/a03d5267-c2a6-469c-819a-e054668dd0f3.jpg',
  沃尔沃:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/aabf2f47-0965-4cf9-a425-e45269479398.jpg',
  东风: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/bf4cebf0-9f53-4ab5-bc7d-abfb87c86f68.jpg',
  捷豹: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/f4279ccb-c466-4f3a-85d1-27b5a6989c1c.jpg',
  北汽绅宝:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/bf84a693-99d2-4313-a520-6915cdd065fb.jpg',
  福田: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/3cb17c1f-3dc2-452f-b51d-b077ba32b8a1.jpg',
  wey: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/d746816e-6144-48c8-a60a-33e510b78c87.jpg',
  英菲尼迪:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/740546d7-61a1-4e33-86d3-be3a0eccac2e.jpg',
  五十铃:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/e8000853-3a78-4fe2-af32-3ca69ba0c287.jpg',
  驭胜: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/2210af62-d114-4718-acd1-7904082a0935.jpg',
  雷诺: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/3e8e2c05-b695-4003-b23a-43c60bacbfcd.jpg',
  林肯: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/d115f3c2-4c5f-457e-a7b6-e95c2357b7d0.jpg',
  北京: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/0078ff5f-06e2-455f-a985-d28931dda78f.jpg',
  铃木: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/8f634cbe-ec64-47ff-ba16-670267da8b93.jpg',
  野马汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/d297e7fd-b73c-44b3-a8e9-19fc05a6c9e7.jpg',
  捷途: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/5a5712cf-ac23-4505-8b05-2763b6134974.jpg',
  一汽: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/387720d8-c5fb-4eac-9cf2-dedeeb206a22.jpg',
  汉腾汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/98ec1213-8b01-4325-8800-b59458d5726f.jpg',
  力帆汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/ae804388-bfd0-4059-8b00-0f2b34d227f9.jpg',
  北汽威旺:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/cb75a05b-621f-429e-9d16-a4af97c1e955.jpg',
  上汽大通:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/8c19598f-1b9e-4855-ae23-e76170b5214c.jpg',
  中兴: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/dcd477e1-e701-436c-84e3-2ca2f4d8c0b4.jpg',
  领克: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/6f6f027f-bc24-4f45-9621-3901475e1b0e.jpg',
  金杯: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/d8287d5d-56dd-4016-8a69-f921844964c0.jpg',
  斯巴鲁:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/a9264f35-ecf4-406e-b626-ce50d7e57642.jpg',
  黄海: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/f2944dde-8081-4cdf-86f8-e8c12c2df405.jpg',
  宝沃: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/c50250aa-9094-4d9a-a65d-23a362854bfb.jpg',
  观致: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/f3e78693-5e64-406c-b4ef-4c718fcbc8b7.jpg',
  ds: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/f042d145-36b0-440e-b833-218fce5e2d58.jpg',
  红旗: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/7270d951-24a0-4462-b797-8401b67d7d33.jpg',
  北京现代:
    'https://songcw-prod.oss-cn-shanghai.aliyuncs.com/201911/26/20191126103651581.png',
  道奇: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/29021135-1fff-4366-af06-f008aa0d90b8.jpg',
  斯威汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/5a036d48-5cd9-40e6-8454-18db241dc257.jpg',
  菲亚特:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/407999a0-a6b8-4056-93a5-6e8166b0d362.jpg',
  mini: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/a150a4ba-434f-4309-b5bf-2f9ed96af10b.jpg',
  凯翼: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/c7230c45-ca30-40ea-8f3e-bfdea56bfe11.jpg',
  讴歌: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/1d7e5302-a4f7-45d3-85e0-1d0c8b68a03d.jpg',
  潍柴英致:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/2ad762bc-f8a9-4289-a80f-231b7d4e7f64.jpg',
  华泰: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/75ca7139-ec4f-417e-afa0-b8ef6d0d09bb.jpg',
  福迪: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/066a85f0-a165-49f3-91be-6203fbb5d3af.jpg',
  昌河: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/6436ffbf-ce6e-4d80-84a9-86093d41fc16.jpg',
  江铃集团轻汽:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/e5b294dd-d5ec-4976-b164-0f71ffe0311c.jpg',
  比速汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/fd4248b7-055a-4467-9232-b5d9ed76c44f.jpg',
  开瑞: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/70810423-5e95-487b-b2ba-e06e608eb1c2.jpg',
  玛莎拉蒂:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/0b73ffb0-4bc9-4022-ae33-b904d0863d68.jpg',
  依维柯:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/047dbdd0-6569-4eaf-9cb5-369692cbf36a.jpg',
  宇通客车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/409e2ce0-b5ab-4e73-9e1b-d34aa54ad345.jpg',
  北汽制造:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/da012815-b942-48c0-b1d1-56926c16b3ca.jpg',
  卡威: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/22d1804f-dcd5-4ee6-bfd3-b23996f16667.jpg',
  双龙: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/a6419dc7-a1bc-43e6-9ace-f15a9af62710.jpg',
  东风风度:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/cc70dbb3-fbe8-4969-8528-e8c3955cc03e.jpg',
  海格: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/b6789116-5352-48a3-8ca4-b9a8240aad17.jpg',
  克莱斯勒:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/7ea21433-7b31-4aed-bb46-a46f6104027c.jpg',
  理念: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/0e09590e-86a6-44cf-8440-dfdd500058b6.jpg',
  smart:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/06ed9dab-7d07-4a4c-9ec9-c70e4434d0bd.jpg',
  东风小康:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/28bd5474-046b-4b99-a9c5-72af9203ffba.jpg',
  '阿尔法·罗密欧':
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/a2c38096-3e3b-4ff5-b461-eaa0b46a02ac.jpg',
  思铭: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/86e516a6-28ff-405a-a2c8-2091e3a2b05b.jpg',
  陕汽通家:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/72da9f0b-c6ee-4b88-ba1d-13883cbefc01.jpg',
  广汽吉奥:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/cec5af5d-0ff3-4b5a-a46a-e742314c2a8e.jpg',
  君马汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/20367a77-2e72-4716-a60c-d8de19dce29b.jpg',
  北汽新能源:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/3b2a9b9d-e0ed-452f-941e-51c6e4ea1d9e.jpg',
  几何汽车: 'http://merchant.songchewang.com/carManagement/addCarModel',
  永源: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/f4403fd4-ceba-4d28-bcfb-62595d8c1373.jpg',
  欧尚汽车: 'http://merchant.songchewang.com/carManagement/addCarModel',
  鑫源: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/4bf3174d-99bc-456e-be39-cb204c76f662.jpg',
  '阿斯顿·马丁':
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/ee78f10c-4c86-42f6-80f8-070597640608.jpg',
  南京金龙:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/f9d8adc8-93ea-4dbb-b88d-115c7ad782c9.jpg',
  长安轻型车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/c55aba32-b4ea-4848-9163-9e43e0b323b0.jpg',
  欧宝: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/50dadbdd-63f1-43ea-b0ac-679e51ab596c.jpg',
  世爵: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/cbe45d6b-d766-4ab1-b406-3a3a03bcb69a.jpg',
  gmc: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/2cb3f369-09b7-426e-8621-4eb3280b9968.jpg',
  双环: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/01a42679-5e2e-4a47-ae65-9619e2ea7e9c.jpg',
  国金汽车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/bad5aec9-e07e-4e3d-a8ae-566dd1959ee3.jpg',
  广汽集团:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/097632f3-90f2-4ea3-ab63-92230ae9503a.jpg',
  欧朗: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/dd9b3402-6606-41eb-acf6-d832de65f407.jpg',
  福田乘用车:
    'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/51ff6847-5944-4ea4-bd0e-e0a1236327f3.jpg',
  金龙: 'https://songcw-v2.oss-cn-shanghai.aliyuncs.com/img/car/brand/c4e110b1-e3bf-4bbf-a80e-4b56a7618ed8.jpg',
};
