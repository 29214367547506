import { GetApplyMonths } from "@/api/Reception2020";

// import TimerTask from '@/utils/timerTask';

const echarts = window.echarts;

// 进件趋势
export default {
  data() {
    return {
      applyTrendPending: false, // 加载进度指示
      applyTrendTask: false, // 定时刷新任务
      applyTrendTaskDuration: 1000 * 60, // 定时任务执行间隔
      applyTrend: [], // 进件趋势数据
      applyTrendChart: null, // echart 实例
      applyTrendChartCurrent: -1, // 高亮索引
      applyTrendChartResize: null, // 尺寸调整节流
      applyTrendChartAutoPlay: null, // 自动高亮定时器
      applyTrendChartAutoPlayDuration: 1000 * 5 // 自动高亮间隔
    };
  },
  methods: {
    // 加载数据
    async loadApplyTrend() {
      try {
        this.stopApplyTrendChartPlay();
        this.cancelApplyTrendChartEvent();
        this.applyTrendPending = true;
        this.applyTrend = await GetApplyMonths();
        this.$nextTick(() => {
          this.initApplyTrendChart(this.parseApplyTrendChartOptions());
        });
        // setTimeout(() => {
        //   this.initApplyTrendChart(this.parseApplyTrendChartOptions());
        // }, 200);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.applyTrendPending = false;
      }
    },

    // 构造图表数据
    parseApplyTrendChartOptions() {
      const { applyTrend } = this;

      const dataOfBar = [...applyTrend].map((row) => row.number);
      const dataOfLine = [...applyTrend].map((row) => row.trend);

      const max = Math.max(...dataOfBar) * 1.1;
      const interval = Math.ceil(max / 4);
      const lineMax = Math.max(...dataOfLine);
      const lineInterval = Math.ceil(lineMax / 2);

      return {
        grid: {
          left: "15%",
          top: "28%",
          bottom: "15%",
          right: "15%"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          backgroundColor: "rgba(54, 85, 219, 0.8)",
          borderRadius: 3,
          borderColor: "rgba(81, 107, 145, 0)",
          borderWidth: 0,
          padding: [4, 6],
          textStyle: {
            color: "#fff",
            fontSize: 12
          },
          shadowStyle: {
            color: "rgba(50,197,255,0.1)"
          },
          formatter: "进件 {c0} 笔，增长率 {c1}%",
          position: function (point, params, dom, rect, size) {
            const _pos = {
              left: point[0] - dom.clientWidth / 2,
              top: 35
            };

            // 如果在左侧，则优先靠左展示
            if (point[0] < size.viewSize[0] / 4) {
              _pos["left"] = 10;
            }

            // 如果在最右侧，则优先靠右展示
            if (point[0] > size.viewSize[0] - size.viewSize[0] / 4) {
              _pos["left"] = size.viewSize[0] - dom.clientWidth - 10;
            }

            return _pos;
          }
        },
        legend: {
          show: true,
          orient: "horizontal",
          right: "5%",
          top: 8,
          itemHeight: 4,
          itemWidth: 16,
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          type: "category",
          data: [...applyTrend].map((row) => `0${row.showMonths}`.slice(-3)),
          axisTick: { show: false },
          axisLabel: { interval: 0, fontSize: "10px", fontWeight: "bold" },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "value",
            min: 0,
            max: interval * 4,
            interval,
            splitLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.3)",
                type: "dotted"
              }
            },
            axisLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.3)",
                width: 2
              }
            },
            axisTick: { inside: true },
            axisLabel: {
              color: "#fff",
              formatter: (params) => params + "笔"
            }
          },
          {
            type: "value",
            min: -(lineInterval * 2),
            max: lineInterval * 2,
            interval: lineInterval,
            splitLine: { show: false },
            axisTick: { inside: true },
            axisLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.3)",
                width: 2
              }
            },
            axisLabel: {
              color: "#fff",
              formatter: (value) => `${value}%`
            }
          }
        ],
        series: [
          {
            type: "bar",
            name: "进件",
            data: dataOfBar,
            barWidth: "50%",
            barGap: "50%",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#76D8FF"
                  },
                  {
                    offset: 1,
                    color: "#365AE0"
                  }
                ],
                false
              ),
              barBorderRadius: [3, 3, 0, 0]
            }
          },
          {
            type: "line",
            name: "增长率",
            data: dataOfLine,
            smooth: true,
            itemStyle: {
              normal: {
                color: "rgba(255, 255, 255, .2)",
                lineStyle: {
                  color: "#fff"
                }
              }
            },
            yAxisIndex: 1,
            showSymbol: false,
            symbolSize: 4
          }
        ],
        animationDelay: (index) => index * 60,
        animationDuration: 600,
        animationEasing: "backOut"
      };
    },

    // 初始化图表
    initApplyTrendChart(options) {
      // 初始化图表
      if (this.$refs && this.$refs.applyTrendChart && !this.applyTrendChart) {
        this.applyTrendChart = echarts.init(this.$refs.applyTrendChart);
      }
      if (this.applyTrendChart) {
        // 装载图表参数
        this.applyTrendChart.setOption(options, true);

        // 启动自动高亮播放
        this.startApplyTrendChartPlay();

        // 添加事件绑定
        this.startApplyTrendChartEvent();
      }
    },

    // 调整图表尺寸
    handleApplyTrendChartResize() {
      if (this.applyTrendChartResize) {
        this.applyTrendChartResize = clearTimeout(this.applyTrendChartResize);
      }
      this.applyTrendChartResize = setTimeout(() => {
        this.applyTrendChart.resize();
        this.applyTrendChartResize = clearTimeout(this.applyTrendChartResize);
      }, 100);
    },

    // 开始自动播放
    startApplyTrendChartPlay() {
      this.applyTrendChartCurrent += 1;
      if (this.applyTrendChartCurrent >= this.applyTrend.length) {
        this.applyTrendChartCurrent = 0;
      }
      this.handleApplyTrendChartToolTips();
      this.applyTrendChartAutoPlay = setTimeout(() => {
        this.startApplyTrendChartPlay();
      }, this.applyTrendChartAutoPlayDuration);
    },

    // 停止自动播放
    stopApplyTrendChartPlay() {
      this.applyTrendChartAutoPlay = clearTimeout(this.applyTrendChartAutoPlay);
    },

    // 播放方法
    handleApplyTrendChartToolTips() {
      this.applyTrendChart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: this.applyTrendChartCurrent
      });
    },

    // 开始事件监听
    startApplyTrendChartEvent() {
      if (this.$refs.applyTrendChart) {
        this.$refs.applyTrendChart.addEventListener(
          "mouseenter",
          this.stopApplyTrendChartPlay
        );
        this.$refs.applyTrendChart.addEventListener(
          "mouseleave",
          this.startApplyTrendChartPlay
        );
      }
      window.addEventListener("resize", this.handleApplyTrendChartResize);
    },

    // 移除事件监听
    cancelApplyTrendChartEvent() {
      if (this.$refs.applyTrendChart) {
        this.$refs.applyTrendChart.removeEventListener(
          "mouseenter",
          this.stopApplyTrendChartPlay
        );

        this.$refs.applyTrendChart.removeEventListener(
          "mouselevel",
          this.startApplyTrendChartPlay
        );
      }

      window.removeEventListener("resize", this.handleApplyTrendChartResize);
    }
  },
  // mounted() {
  //   console.log("mounted - applyTrend");
  //   // 开始定时任务
  //   this.loadApplyTrend();
  //   // this.applyTrendTask = new TimerTask({
  //   //   task: this.loadApplyTrend,
  //   //   duration: this.applyTrendTaskDuration,
  //   // });
  // },
  beforeDestroy() {
    // 结束定时任务
    this.applyTrendTask.dispose();
    // 结束自动播放
    this.stopApplyTrendChartPlay();
    // 移除事件监听
    this.cancelApplyTrendChartEvent();
  }
};
