import * as http from '../http';

// 2020 版数据大屏接口

// 进件概览
export const GetApplyApplyInfo = async () => {
  const res = await http.get('/visualization/incoming/applyInfo');
  return res.data;
};

// 累计回款
export const GetApplyAccumulatedCollection = async () => {
  const res = await http.get('/visualization/incoming/accumulatedCollection');
  return res.data;
};

// 今日回款
export const GetApplyCollectionToday = async () => {
  const res = await http.get('/visualization/incoming/collectionToday');
  return res.data;
};

// 累计融资金额及笔数
export const GetApplyCumulativeFinancing = async () => {
  const res = await http.get('/visualization/incoming/cumulativeFinancing');
  return res.data;
};

// 今日融资金额及笔数
export const GetApplyTodayFinancing = async () => {
  const res = await http.get('/visualization/incoming/todayFinancing');
  return res.data;
};

// 12月趋势
export const GetApplyMonths = async () => {
  const res = await http.get('/visualization/incoming/months');
  return res.data;
};

// 订单实时流转
export const GetApplyRealTimeStatus = async () => {
  const res = await http.get('/visualization/incoming/realTimeStatus');
  return res.data;
};

// 区域融资余额
export const GetApplyRegion = async () => {
  const res = await http.get('/visualization/incoming/region');
  return res.data;
};

// 省融资余额
export const GetApplyProvince = async () => {
  const res = await http.get('/visualization/incoming/province');
  return res.data;
};

// 进件 top3 地区信息
export const GetApplyTop3AreaInfo = async () => {
  const res = await http.get('/visualization/incoming/top3AreaInfo');
  return res.data;
};

// 进件top3 车辆信息
export const GetApplyTop3CarInfo = async () => {
  const res = await http.get('/visualization/incoming/top3CarInfo');
  return res.data;
};

// 融资总余额
export const GetApplyTotalFinancingBalance = async () => {
  const res = await http.get('/visualization/incoming/totalFinancingBalance');
  return res.data;
};

// 成交区域排名
export const GetApplyTranAreaAndAgent = async () => {
  const res = await http.get('/visualization/incoming/tranAreaAndAgent');
  return res.data;
};

// 融资回款趋势
export const GetApplyTrend = async () => {
  const res = await http.get('/visualization/incoming/trend');
  return res.data;
};

// 成交概览
export const GetApplyIncomingOverview = async () => {
  const res = await http.get('/visualization/incoming/incomingOverview');
  return res.data;
};

// 所有融资余额
export const GetApplyAllBalance = async () => {
  const res = await http.get('/visualization/incoming/getAllBalance');
  return res.data;
};

// 今日融资余额变动
export const GetApplyDayBalanceChange = async () => {
  const res = await http.get('/visualization/incoming/getDayBalanceChange');
  return res.data;
};

// 客户概览-年龄段
export const GetCustomerAgeInfo = async () => {
  const res = await http.get('/visualization/customer/getCustomerAgeInfo');
  if (!res) return null;
  return res.data;
};

// 客户概览-性别
export const GetCustomerSexInfo = async () => {
  const res = await http.get('/visualization/customer/getCustomerSexInfo');
  return res.data;
};
