import { GetApplyIncomingOverview } from "../../../api/Reception2020";
// import TimerTask from '@/utils/timerTask';

// 进件概览
export default {
  data() {
    return {
      applyOverviewPending: false, // 加载指示器
      applyOverviewTask: null, // 定时刷新任务
      applyOverviewTaskDuration: 1000 * 60, // 定时任务执行间隔
      applyOverview: {
        incomingFinancing: { amount: 0, number: 0 },
        financingBalance: { amount: 0, number: 0 }
      }
    };
  },
  computed: {
    // 融资余额金额
    financingBalanceAmount() {
      return this.applyOverview.financingBalance.amount;
    }
  },
  methods: {
    // 加载进件概览数据
    async loadApplyOverview() {
      try {
        this.applyOverviewPending = true;
        this.applyOverview = await GetApplyIncomingOverview();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.applyOverviewPending = false;
      }
    }
  },
  // mounted() {
  //   // this.applyOverviewTask = new TimerTask({
  //   //   task: this.loadApplyOverview,
  //   //   duration: this.applyOverviewTaskDuration,
  //   // });
  //   console.log("applyOverview - Mounted");
  //   this.loadApplyOverview();
  // },
  beforeDestroy() {
    this.applyOverviewTask.dispose();
    this.cancelApplyRegionChartEvent();
  }
};
