import qs from 'qs';
import * as http from '../http';

// 全局应用

// 用户登录
export const ApiLogin = async (params) => {
  params = {
    grant_type: 'password',
    scope: 'userProfile',
    ...params,
  };
  if (!params.username) throw '请填写用户名！';
  if (params.username.length < 2 || params.username.length > 16)
    throw '用户名格式错误，请重新填写！';

  if (!params.password) throw '请填写登录密码！';
  if (params.password.length < 6 || params.password.length > 16)
    throw '密码格式错误，请重新填写！';

  // 拼接验证信息
  const authToken = window.btoa(
    ['fIv0wBGvBYd1fTSvXGa1UviI', 'xAgRbi5RtELo6P7maJWVPhwx5u9i48dQ'].join(':')
  );

  const res = await http.post('/login/token', qs.stringify(params), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${authToken}`,
    },
  });

  return res.data;
};
