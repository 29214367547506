<template>
  <div class="sc-dv-data sc-dv-amount">
    <div v-if="unit" class="unit">（{{ unit }}）</div>
    <div
      v-for="(set, index) in amountData"
      :key="index"
      :class="['scroller', `scroller-${set.name}`]"
    >
      <div v-if="divider && set.divider" class="divider">{{ set.divider }}</div>
      <div class="box">
        <div class="inner">
          <i />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { amount } from '../../../utils/tools';

export default {
  name: 'Amount',
  props: {
    // 金额
    value: {
      type: [Number, String],
      default: 0,
    },

    // 是否显示分位
    divider: {
      type: Boolean,
      default: true,
    },

    // 单位
    unit: {
      type: String,
      default: '元',
    },
  },
  computed: {
    // 使用的金额标识
    amountData: function () {
      const { value } = this.$props;
      const _value = amount(value, { fixed: 0 });
      if (!_value) return [];
      // 单位符号
      const divider = ['百', '十万', '亿', '千亿'];
      let _dividerIndex = 0;
      // 符号查询
      const symbol = {
        '-': 'dash',
        '.': 'dot',
        ',': 'comma',
        ':': 'colon',
        '?': 'unknow',
      };

      return _value
        .toString()
        .split('')
        .reverse()
        .map((set, index) => {
          let _divider = '';
          if (index % 4 === 2) {
            _divider = divider[_dividerIndex];
            _dividerIndex += 1;
          }

          if (isNaN(+set)) {
            return {
              name: symbol[set] || 'unknow',
              value: set,
              divider: _divider,
            };
          } else {
            return {
              name: set,
              value: set,
              divider: _divider,
            };
          }
        })
        .reverse();
    },
  },
};
</script>

<style lang="scss" scoped>
// 单个数字的宽高比例
$ratio: 410/248;

// 字符宽度
$size: 36px;

.sc-dv-amount {
  color: #fff;
  display: flex;
  font-size: 20px;
  padding-top: 32px;
  position: relative;
  z-index: 999;

  // 单位
  .unit {
    font-size: 1rem;
    font-weight: bold;
    line-height: 28px;
    right: 0;
    top: 0;
    position: absolute;
  }

  // 数字滚动区域 248*410
  .scroller {
    height: $ratio * $size;
    position: relative;
    width: $size;

    // 分位
    .divider {
      color: #00fff5;
      font-size: 0.8rem;
      font-weight: bold;
      line-height: 28px;
      left: 0;
      top: -42px;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    .box {
      height: $ratio * $size;
      overflow: hidden;
      width: $size;
    }

    .inner {
      background: url('/images/2020/number-style-001-ver.svg') no-repeat 0 0;
      background-size: cover;
      height: $ratio * $size * 14;
      position: relative;
      transition: transform 1s ease;
      width: $size;
    }

    &-1 .inner {
      transform: translate3d(0, 0, 0);
    }
    &-2 .inner {
      transform: translate3d(0, -1 * $ratio * $size, 0);
    }
    &-3 .inner {
      transform: translate3d(0, -2 * $ratio * $size, 0);
    }
    &-4 .inner {
      transform: translate3d(0, -3 * $ratio * $size, 0);
    }
    &-5 .inner {
      transform: translate3d(0, -4 * $ratio * $size, 0);
    }
    &-6 .inner {
      transform: translate3d(0, -5 * $ratio * $size, 0);
    }
    &-7 .inner {
      transform: translate3d(0, -6 * $ratio * $size, 0);
    }
    &-8 .inner {
      transform: translate3d(0, -7 * $ratio * $size, 0);
    }
    &-9 .inner {
      transform: translate3d(0, -8 * $ratio * $size, 0);
    }
    &-0 .inner {
      transform: translate3d(0, -9 * $ratio * $size, 0);
    }
    &-dash .inner {
      transform: translate3d(0, -10 * $ratio * $size, 0);
    }
    &-dot .inner {
      transform: translate3d(0, -11 * $ratio * $size, 0);
    }
    &-comma .inner {
      transform: translate3d(0, -12 * $ratio * $size, 0);
    }
    &-colon .inner {
      transform: translate3d(0, -13 * $ratio * $size, 0);
    }
    &-unknow .inner {
      transform: translate3d(0, -14 * $ratio * $size, 0);
    }
  }
}
</style>
