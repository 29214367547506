<template>
  <div class="sc-dv-spin">
    <Spinner name="line-scale-pulse-out" height="32" width="48" />
  </div>
</template>

<script>
import Spinner from 'vue-spinkit';

import 'vue-spinkit/lib/vue-spinkit.css';

export default {
  name: 'Spin',
  components: {
    Spinner,
  },
};
</script>

<style lang="scss" scoped>
.sc-dv-spin {
  background-image: radial-gradient(
    fade-out(#2c536c, 0.5) 0,
    fade-out(#2c536c, 1) 70%
  );
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}
</style>
