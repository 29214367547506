<template>
  <div class="marquee-table" @mouseleave="marquee" @mousenter="stopMarquee">
    <div class="header">
      <span
        class="cell"
        v-for="(cell, index) in columns"
        :key="index"
        :style="{
          width: cell.width ? `${cell.width}px` : 'auto',
          flex: cell.width ? 'unset' : 1,
        }"
      >
        {{ cell.title }}
      </span>
    </div>
    <div class="body" ref="body">
      <div class="scroll-wrap" ref="scrollWrap">
        <div class="inner" ref="scroll">
          <div class="row" v-for="(row, rowIndex) in data" :key="rowIndex">
            <span
              class="cell"
              v-for="(cell, cellIndex) in columns"
              :key="cellIndex"
              :style="{
                width: cell.width ? `${cell.width}px` : 'auto',
                flex: cell.width ? 'unset' : 1,
              }"
            >
              {{ row[cell.key] }}
            </span>
          </div>
        </div>
        <div class="inner">
          <div class="row" v-for="(row, rowIndex) in data" :key="rowIndex">
            <span
              class="cell"
              v-for="(cell, cellIndex) in columns"
              :key="cellIndex"
              :style="{
                width: cell.width ? `${cell.width}px` : 'auto',
                flex: cell.width ? 'unset' : 1,
              }"
            >
              {{ row[cell.key] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarqueeTable',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      marqueeTimer: null, // 滚动定时器
      offset: 0, // 偏移
      speed: 1, // 滚动速度
    };
  },
  methods: {
    // 滚动执行
    marquee() {
      this.marqueeTimer = clearTimeout(this.marqueeTimer);
      // 最大滚动高度
      const maxOffset = this.$refs.scroll.clientHeight;
      // 可视区域高度
      const scrollSize = this.$refs.body.clientHeight;

      if (maxOffset < scrollSize) return;

      if (!this.$props.data.length) return;
      this.offset += this.speed;
      if (this.offset >= maxOffset) {
        this.offset = 0;
      }

      this.$refs.scrollWrap.style.transform = `translateY(-${this.offset}px)`;

      this.marqueeTimer = setTimeout(() => {
        this.marquee();
      }, 30);
    },

    // 停止滚动
    stopMarquee() {
      this.marqueeTimer = clearTimeout(this.marqueeTimer);
    },
  },
  mounted() {
    this.marquee();
  },
  beforeDestroy() {
    this.marqueeTimer = clearTimeout(this.marqueeTimer);
    this.stopMarquee();
  },
};
</script>

<style lang="scss">
.marquee-table {
  font-size: 0.8rem;
  position: relative;
  height: 100%;
  width: 100%;

  // 表头
  .header {
    background-color: rgba(81, 125, 169, 0.2);
    color: #fff;
    border-radius: 4px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .cell {
      flex: 1;
      font-size: 0.8rem;
      line-height: 28px;
      overflow: hidden;
      padding: 4px 8px;
      position: relative;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // 内容
  .body {
    border-radius: 4px;
    height: (calc(100% - 38px));
    overflow: hidden;
    position: relative;
    z-index: 1;

    .row {
      background-color: rgba(81, 125, 169, 0.1);
      color: #fff;
      border-radius: 4px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 2px;
      position: relative;
      z-index: 1;

      .cell {
        flex: 1;
        line-height: 24px;
        overflow: hidden;
        padding: 8px;
        position: relative;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    &::after {
      content: '';
      background-image: linear-gradient(
        to top,
        rgba(9, 23, 36, 0.56),
        rgba(10, 10, 14, 0)
      );
      position: absolute;
      height: 32px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }
  }
}
</style>
