import { GetCustomerSexInfo, GetCustomerAgeInfo } from "@/api/Reception2020";

// import TimerTask from '@/utils/timerTask';

const echarts = window.echarts;

// 客户概览
export default {
  data() {
    return {
      customerOverviewPending: false, // 加载指示
      customerOverviewTask: null, // 定时刷新任务
      customerOverviewTaskDuration: 1000 * 60 * 10,
      customerOverviewSex: {
        man: 0,
        woman: 0,
        manRatio: 0,
        womanRatio: 0
      }, // 性别
      customerOverviewAge: {}, // 年龄分布
      customerOverviewAgeChart: null, // 年龄分布图表
      customerOverviewAgeChartResize: null // 图表尺寸调整防抖
    };
  },
  methods: {
    // 加载客户概览
    async loadCustomerOverview() {
      try {
        this.cancelCustomerOverviewAgeChartEvent();

        this.customerOverviewPending = true;

        // 性别
        const _sex = await GetCustomerSexInfo();
        this.customerOverviewSex = {
          man: _sex.maleNum,
          woman: _sex.femaleNum,
          manRatio: parseInt(_sex.maleRate),
          womanRatio: parseInt(_sex.femaleRate)
        };

        // 年龄段
        this.customerOverviewAge = await GetCustomerAgeInfo();

        this.initCustomerOverviewAgeChart(
          this.parseCustomerOverviewAgeChartOptions()
        );
      } catch (error) {
        this.handleError(error);
      } finally {
        this.customerOverviewPending = false;
      }
    },

    // 构造年龄段图表数据
    parseCustomerOverviewAgeChartOptions() {
      const { customerOverviewAge } = this;

      const data = [...customerOverviewAge.ageLevelInfo].map((row) => {
        return {
          name: row.level,
          value: +row.num
        };
      });

      return {
        color: [
          "#516b91",
          "#59c4e6",
          "#edafda",
          "#93b7e3",
          "#a5e7f0",
          "#cbb0e3"
        ],
        title: {
          text: [
            `{sum|${customerOverviewAge.avgAge}}`,
            "{title|平均年龄}"
          ].join("\n"),
          textStyle: {
            color: "#fff",
            rich: {
              sum: {
                fontSize: 18,
                fontWeight: "bold",
                color: "#fff",
                padding: [4, 0]
              },
              title: {
                fontSize: 12,
                fontWeight: "normal",
                color: "rgba(255, 255, 255, .8)"
              }
            }
          },
          top: "center",
          left: "center"
        },
        series: {
          type: "pie",
          data,
          roseType: false,
          startAngle: 75,
          radius: ["40%", "60%"],
          center: ["50%", "50%"],
          label: {
            show: true,
            color: "#fff",
            formatter: (params) => {
              return [
                `{name|${params.name} 岁}`,
                `{rate|${Math.floor(params.percent, 10)}%}`
              ].join("\n");
            },
            rich: {
              name: {
                color: "#00FFF5",
                fontSize: 10
              },
              rate: {
                color: "#fff",
                fontSize: 14,
                fontWeight: "bold",
                lineHeight: 20
              }
            }
          },
          labelLine: {
            lineStyle: {
              color: "#fff"
            }
          },
          emphasis: {
            label: {
              show: true
            }
          }
        }
      };
    },

    // 初始化年龄段图表
    initCustomerOverviewAgeChart(options) {
      // 初始化图表
      if (
        this.$refs &&
        this.$refs.customerOverviewAgeChart &&
        !this.customerOverviewAgeChart
      ) {
        this.customerOverviewAgeChart = echarts.init(
          this.$refs.customerOverviewAgeChart
        );
      }
      if (this.customerOverviewAgeChart) {
        // 装载图表参数
        this.customerOverviewAgeChart.setOption(options, true);

        // 初始化事件
        this.startCustomerOverviewAgeChartEvent();
      }
    },

    // 调整图表尺寸
    handleCustomerOverviewAgeChartResize() {
      if (this.customerOverviewAgeChartResize) {
        this.customerOverviewAgeChartResize = clearTimeout(
          this.customerOverviewAgeChartResize
        );
      }
      this.customerOverviewAgeChartResize = setTimeout(() => {
        this.customerOverviewAgeChart.resize();
        this.customerOverviewAgeChartResize = clearTimeout(
          this.customerOverviewAgeChartResize
        );
      }, 100);
    },

    // 开始事件监听
    startCustomerOverviewAgeChartEvent() {
      window.addEventListener(
        "resize",
        this.handleCustomerOverviewAgeChartResize
      );
    },

    // 移除事件监听
    cancelCustomerOverviewAgeChartEvent() {
      window.removeEventListener(
        "resize",
        this.handleCustomerOverviewAgeChartResize
      );
    }
  },
  // mounted() {
  //   // this.customerOverviewTask = new TimerTask({
  //   //   task: this.loadCustomerOverview,
  //   //   duration: this.customerOverviewTaskDuration,
  //   // });
  //   console.log("mounted - customerOverview");
  //   this.loadCustomerOverview();
  // },
  beforeDestroy() {
    this.customerOverviewTask.dispose();
    this.cancelCustomerOverviewAgeChartEvent();
  }
};
