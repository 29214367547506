<template>
  <div class="sc-dv-counter">
    <div class="title-wrap">
      <div class="title" v-if="title">
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="base" v-if="base">
      <span>{{ base }}</span>
      <em v-if="baseSuffix">{{ baseSuffix }}</em>
    </div>
    <div class="sub" v-if="sub || subPlacehold">
      <span>{{ sub }}</span>
      <em v-if="subSuffix">{{ subSuffix }}</em>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataCounter',
  props: {
    // 标题
    title: String,

    // 主要参数
    base: [String, Number],

    // 主要参数后缀
    baseSuffix: String,

    // 次要参数
    sub: [String, Number],

    // 次要参数后缀
    subSuffix: String,

    // 次要参数占位
    subPlacehold: Boolean,
  },
};
</script>

<style lang="scss" scoped>
// 边距
$void: 10px;

// 标题装饰
$titleAdornColor: #00fff5;
$titleAdornSize: 8px;
$titleAdornWidth: 2px;

// 参数
$suffixColor: #a8addc;
$baseColor: #ffd562;
$subColor: #fff;

.sc-dv-counter {
  padding: $void;
  position: relative;

  // 标题
  .title {
    color: #a2a8dd;
    display: inline-block;
    position: relative;
    text-align: center;

    &-wrap {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &::before,
    &::after {
      border-color: $titleAdornColor;
      border-style: solid;
      content: '';
      position: absolute;
      height: $titleAdornSize;
      width: $titleAdornSize;
    }

    &::before {
      border-width: $titleAdornWidth 0 0 $titleAdornWidth;
      left: -($titleAdornSize / 2 + $titleAdornWidth);
      top: -($titleAdornSize / 2 + $titleAdornWidth);
    }

    &::after {
      border-width: 0 $titleAdornWidth $titleAdornWidth 0;
      right: -($titleAdornSize / 2 + $titleAdornWidth);
      bottom: -($titleAdornSize / 2 + $titleAdornWidth);
    }

    span {
      display: block;
      font-size: 1rem;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // 主要参数
  .base {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-top: $void;

    span,
    em {
      display: block;
      font-style: normal;
    }

    span {
      font-family: 'DIN Alternate Bold';
      color: $baseColor;
      font-size: 1.5rem;
      font-weight: normal;
      line-height: 32px;
      text-align: right;
    }

    em {
      color: $suffixColor;
      flex-shrink: 0;
      font-size: 0.8rem;
      line-height: 28px;
      margin-left: 8px;
    }
  }

  // 次要参数
  .sub {
    align-items: flex-end;
    display: flex;
    justify-content: center;

    span,
    em {
      display: block;
      font-style: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      font-family: 'DIN Alternate Bold';
      color: $subColor;
      font-size: 1.1rem;
      height: 32px;
      line-height: 32px;
      text-align: right;
    }

    em {
      color: $suffixColor;
      flex-shrink: 0;
      font-size: 0.8rem;
      line-height: 28px;
      margin-left: 8px;
    }
  }
}
</style>
