import Video from '../components/Video.vue';

// 视频播放间隔预设（毫秒）
const VIDEO_INTERVAL = [
  { label: '10秒·DEV', value: 1000 * 10 },
  { label: '20分钟', value: 1000 * 60 * 20 },
  { label: '30分钟', value: 1000 * 60 * 30 },
  { label: '1小时', value: 1000 * 60 * 60 },
  { label: '2小时', value: 1000 * 60 * 60 * 2 },
  { label: '3小时', value: 1000 * 60 * 60 * 3 },
  { label: '4小时', value: 1000 * 60 * 60 * 4 },
  { label: '6小时', value: 1000 * 60 * 60 * 6 },
  { label: '8小时', value: 1000 * 60 * 60 * 8 },
];

// 宣传片控制
export default {
  components: {
    Video,
  },
  data() {
    return {
      videoOpen: false, // 是否正在播放视频
      videoPanelActive: false, // 视频控制面板是否开启
      videoPanelAutoCloseDuration: 30, // 视频控制面板开启后，30秒无操作自动收起
      videoPanelAutoCloseTimer: null, // 自动收起定时器
      videoIntervalPreset: VIDEO_INTERVAL,
      videoInterval: VIDEO_INTERVAL[1].value,
      videoEnable: true,
    };
  },
  watch: {
    videoPanelActive(visible) {
      if (!visible) return;
      this.handleVideoPanelAutoClose();
    },
  },
  methods: {
    handleVideoToggle(visible) {
      this.videoOpen = visible;
    },
    
    // 视频面板自动关闭
    handleVideoPanelAutoClose() {
      this.videoPanelAutoCloseTimer = clearTimeout(
        this.videoPanelAutoCloseTimer
      );

      this.videoPanelAutoCloseDuration -= 1;

      if (this.videoPanelAutoCloseDuration <= 0) {
        this.videoPanelActive = false;
        this.videoPanelAutoCloseDuration = 30;
        return;
      }

      this.videoPanelAutoCloseTimer = setTimeout(() => {
        this.handleVideoPanelAutoClose();
      }, 1000);
    },
  },
  beforeDestroy() {
    this.videoPanelAutoCloseTimer = clearTimeout(this.videoPanelAutoCloseTimer);
  },
};
