// HTTP 封装
import qs from 'qs';
import axios from 'axios';

// Axios 配置
axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
axios.defaults.timeout = 1000 * 30; // 超时时间
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.platform = 'FINANCE_DATA_PC';

/**
 * URL 参数混入
 * @param {String} url URL
 * @param {Object} params 参数
 * @param {boolean} remove 匹配成功时，是否从参数中移除
 */
function _mixin(url, params, remove = true) {
  if (!Array.isArray(params)) {
    for (const key in params) {
      const reg = new RegExp(`\\{${key}\\}`, 'g');
      if (reg.test(url)) {
        url = url.replace(reg, params[key]);
        if (remove) {
          delete params[key];
        }
      }
    }
  }
  return url;
}

// 请求拦截器
axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

// 响应拦截器
axios.interceptors.response.use(
  (res) => res.data,
  (error) => Promise.reject(error)
);

/**
 * 发送请求
 * @param {*} config
 */
const request = function (config) {
  if (config.url) {
    if (config.data && config.data instanceof Object) {
      config.url = _mixin(config.url, config.data);
    }
    if (config.params && config.params instanceof Object) {
      config.url = _mixin(config.url, config.params);
    }
  }

  if (config.data) {
    // 分页信息需要放在 URL 中
    try {
      // 取出分页相关字段
      const data = {
        ...config.data,
      };
      const pageParams = {};

      // 滤除分页相关的字段
      for (let key in data) {
        if (['pageNo', 'pageSize'].includes(key)) {
          let value = data[key];

          // 当前页码从第一页开始
          if (key === 'pageNo') {
            value = Math.max(1, +value || 0);
          }

          // 必须设置每页显示条数
          if (key === 'pageSize') {
            value = +value || 0;
            value = value === 0 ? 20 : Math.max(1, value);
          }

          pageParams[key] = value;
          delete data[key];
        }
      }

      // 混合 URL
      if (JSON.stringify(pageParams) !== '{}') {
        config.params = {
          ...config.params,
          ...pageParams,
        };
      }
    } catch (error) {
      console.log(error);
    }
  }

  return axios(config);
};

// 封装 GET 方法
export const get = function (url, params = {}, options = {}) {
  return request({
    method: 'GET',
    url,
    params,
    options,
  });
};

// 封装 POST 方法
export const post = function (
  url,
  data = {},
  options = {
    formatPage: true,
  }
) {
  // 分页信息需要放在 URL 中
  try {
    // 取出分页相关字段
    const pageParams = {};
    if (options.formatPage) {
      // 滤除分页相关的字段
      for (let key in data) {
        if (data[key]) {
          if (['pageNo', 'pageSize'].includes(key)) {
            let value = data[key];

            // 当前页码从第一页开始
            if (key === 'pageNo') {
              value = Math.max(1, +value || 0);
            }

            // 必须设置每页显示条数
            if (key === 'pageSize') {
              value = +value || 0;
              value = value === 0 ? 20 : Math.max(1, value);
            }

            pageParams[key] = value;
            delete data[key];
          }
        }
      }
    }
    // 混合 URL
    if (JSON.stringify(pageParams) !== '{}') {
      if (url.includes('?')) {
        url = `${url}&${qs.stringify(pageParams)}`;
      } else {
        url = `${url}?${qs.stringify(pageParams)}`;
      }
    }
  } catch (error) {
    console.log(error);
  }

  return request({
    method: 'POST',
    url,
    data,
    ...options,
  });
};

// 封装 PUT 方法
export const put = function (url, data = {}, options = {}) {
  return request({
    method: 'PUT',
    url,
    data,
    ...options,
  });
};

// 封装 PATCH 方法
export const patch = function (url, data = {}, options = {}) {
  return request({
    method: 'PATCH',
    url,
    data,
    ...options,
  });
};

// 封装 DELETE 方法
export const del = function (url, data = {}, options = {}) {
  return request({
    method: 'DELETE',
    url,
    data,
    ...options,
  });
};
