import { GetApplyTop3AreaInfo, GetApplyTop3CarInfo } from "@/api/Reception2020";

// import TimerTask from '@/utils/timerTask';

import { carBrandLogo } from "@/lib/carBrand";

const echarts = window.echarts || function () {};

// 进件地区和汽车品牌 Top3 榜单
export default {
  data() {
    return {
      applyTopicPending: false,
      applyTopicTask: null, // 定时刷新
      applyTopicTaskDuration: 1000 * 60 * 10, // 定时刷新间隔
      applyTopicRegion: [], // 地区
      applyTopicRegionChart: null, // 地区图表
      applyTopicRegionChartResize: null, // 地区图表尺寸调整防抖
      applyTopicCar: [], // 汽车品牌
      applyTopicCarChart: null, // 汽车图表
      applyTopicCarChartResize: null // 汽车图表尺寸调整防抖
    };
  },
  methods: {
    async loadApplyTopic() {
      try {
        this.cancelApplyTopicRegionEvent();
        this.cancelApplyTopicCarEvent();
        this.applyTopicPending = true;
        this.applyTopicRegion = await GetApplyTop3AreaInfo();
        this.applyTopicCar = await GetApplyTop3CarInfo();
        this.$nextTick(() => {
          this.initApplyTopicRegionChart(this.parseApplyTopicRegionChart());
          this.initApplyTopicCarChart(this.parseApplyTopicCarChart());
        });
        // setTimeout(() => {
        //   this.initApplyTopicRegionChart(this.parseApplyTopicRegionChart());
        //   this.initApplyTopicCarChart(this.parseApplyTopicCarChart());
        // }, 200);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.applyTopicPending = false;
      }
    },

    //////////////////////////////////////////
    // 地区TOP3处理

    // 地区TOP3图表数据
    parseApplyTopicRegionChart() {
      const { applyTopicRegion } = this;

      const data = [...applyTopicRegion].map((row) => +row.count || 0);

      const max = Math.max(...data) * 1.1;
      const interval = Math.ceil(max / 4);

      return {
        grid: {
          left: "20%",
          top: "20%",
          bottom: "20%",
          right: "10%"
        },
        xAxis: {
          type: "category",
          data: [...applyTopicRegion].map((row) =>
            row.areaName.replace(
              /省|市|维吾尔自治区|回族自治区|壮族自治区|自治区/g,
              ""
            )
          ),
          axisLabel: {
            show: true,
            color: "#fff"
          },
          axisLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.3)",
              width: 2
            }
          }
        },
        yAxis: {
          type: "value",
          min: 0,
          max: interval * 4,
          interval,
          splitLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.3)",
              type: "dotted"
            }
          },
          axisLine: { show: false },
          axisTick: { inside: true },
          axisLabel: {
            show: true,
            color: "#fff"
          }
        },
        series: [
          {
            type: "bar",
            data: data,
            barWidth: "50%",
            barGap: "50%",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#76D8FF"
                  },
                  {
                    offset: 1,
                    color: "#365AE0"
                  }
                ],
                false
              ),
              barBorderRadius: [3, 3, 0, 0]
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "bolder",
              formatter: "{c} 笔"
            }
          },

          {
            type: "pictorialBar",
            silent: true,
            barWidth: "50%",
            barGap: "50%",
            symbol: "rect",
            symbolSize: ["80%", "10%"],
            symbolOffset: [0, 8],
            symbolMargin: 2,
            symbolRepeat: true,
            symbolClip: true,
            data: data,
            itemStyle: {
              color: "rgba(255, 255, 255, .3)"
            },
            z: 102
          }
        ]
      };
    },

    // 初始化地区TOP3图表
    initApplyTopicRegionChart(options) {
      // 初始化图表
      if (
        this.$refs &&
        this.$refs.applyTopicRegionChart &&
        !this.applyTopicRegionChart
      ) {
        this.applyTopicRegionChart = echarts.init(
          this.$refs.applyTopicRegionChart
        );
      }

      if (this.applyTopicRegionChart) {
        // 装载图表参数
        this.applyTopicRegionChart.setOption(options, true);

        // 事件
        this.startApplyTopicRegionEvent();
      }
    },

    // 调整地区TOP3图表尺寸
    handleApplyTopicChartRegionResize() {
      if (this.applyTopicRegionChartResize) {
        this.applyTopicRegionChartResize = clearTimeout(
          this.applyTopicRegionChartResize
        );
      }
      this.applyTopicRegionChartResize = setTimeout(() => {
        this.applyTopicRegionChart.resize();
        this.applyTopicRegionChartResize = clearTimeout(
          this.applyTopicRegionChartResize
        );
      }, 100);
    },

    // 开始事件监听
    startApplyTopicRegionEvent() {
      window.addEventListener("resize", this.handleApplyTopicChartRegionResize);
    },

    // 移除事件监听
    cancelApplyTopicRegionEvent() {
      window.removeEventListener(
        "resize",
        this.handleApplyTopicChartRegionResize
      );
    },

    //////////////////////////////////////////
    // 车型TOP3

    // 车型TOP3图表数据
    parseApplyTopicCarChart() {
      const { applyTopicCar } = this;

      const data = [...applyTopicCar]
        .map((row) => {
          const brandName = row.carSeriesName.split("-")[0];
          const brandLogo = carBrandLogo[brandName];
          return {
            name: row.carSeriesName,
            value: +row.count || 0,
            brandName,
            brandLogo
          };
        })
        .reverse();

      return {
        grid: {
          left: "10%",
          top: "0%",
          bottom: "10%",
          right: "20%"
        },
        xAxis: {
          type: "value",
          data,
          splitLine: { show: false },
          axisLine: { show: false },
          axisLabel: { show: false }
        },
        yAxis: {
          type: "category",
          splitLine: { show: false },
          axisLine: { show: false },
          axisLabel: { show: false }
        },
        series: [
          {
            type: "bar",
            data,
            barWidth: "40%",
            itemStyle: {
              color: "transparent"
            },
            label: {
              show: true,
              position: "insideLeft",
              color: "#fff",
              fontWeight: "bolder",
              formatter: (params) => {
                let _brandAlias = "";
                if (params.data.brandName === "大众") {
                  _brandAlias = "carDaZhong";
                }
                if (params.data.brandName === "日产") {
                  _brandAlias = "carRiChan";
                }
                if (params.data.brandName === "哈弗") {
                  _brandAlias = "carHaFu";
                }
                return "{" + _brandAlias + "|}{name|" + params.name + "}";
              },
              rich: {
                carDaZhong: {
                  backgroundColor: {
                    image: carBrandLogo["大众"]
                  },
                  height: 16,
                  width: 16
                },
                carRiChan: {
                  backgroundColor: {
                    image: carBrandLogo["日产"]
                  },
                  height: 16,
                  width: 16
                },
                carHaFu: {
                  backgroundColor: {
                    image: carBrandLogo["哈弗"]
                  },
                  height: 16,
                  width: 16
                },
                name: {
                  color: "#fff",
                  fontSize: 12,
                  padding: [0, 0, 0, 4]
                }
              }
            }
          },
          {
            type: "bar",
            data,
            barWidth: "20%",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                1,
                0,
                0,
                0,
                [
                  {
                    offset: 0,
                    color: "#76D8FF"
                  },
                  {
                    offset: 1,
                    color: "#365AE0"
                  }
                ],
                false
              ),
              barBorderRadius: 10
            },
            label: {
              show: true,
              position: "right",
              color: "#fff",
              fontWeight: "bolder",
              formatter: (params) => `${params.value}笔`
            }
          }
        ]
      };
    },

    // 初始化车型TOP3图表
    initApplyTopicCarChart(options) {
      // 初始化图表
      if (
        this.$refs &&
        this.$refs.applyTopicCarChart &&
        !this.applyTopicCarChart
      ) {
        this.applyTopicCarChart = echarts.init(this.$refs.applyTopicCarChart);
      }

      if (this.applyTopicCarChart) {
        // 装载图表参数
        this.applyTopicCarChart.setOption(options, true);

        // 事件监听
        this.startApplyTopicCarEvent();
      }
    },

    // 调整车型TOP3图表尺寸
    handleApplyTopicChartCarResize() {
      if (this.applyTopicCarChartResize) {
        this.applyTopicCarChartResize = clearTimeout(
          this.applyTopicCarChartResize
        );
      }
      this.applyTopicCarChartResize = setTimeout(() => {
        this.applyTopicCarChart.resize();
        this.applyTopicCarChartResize = clearTimeout(
          this.applyTopicCarChartResize
        );
      }, 100);
    },

    // 开始事件监听
    startApplyTopicCarEvent() {
      window.addEventListener("resize", this.handleApplyTopicChartCarResize);
    },

    // 移除事件监听
    cancelApplyTopicCarEvent() {
      window.removeEventListener("resize", this.handleApplyTopicChartCarResize);
    }

    //////////////////////////////////////////
  },
  // mounted() {
  //   // this.applyTopicTask = new TimerTask({
  //   //   task: this.loadApplyTopic,
  //   //   duration: this.applyTopicTaskDuration,
  //   // });
  //   console.log("mounted - applyTopic");
  //   this.loadApplyTopic();
  // },
  beforeDestroy() {
    this.applyTopicTask.dispose();
    this.cancelApplyTopicRegionEvent();
    this.cancelApplyTopicCarEvent();
  }
};
