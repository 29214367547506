<template>
  <div class="sc-dv-data sc-dv-data-section">
    <div v-if="title" class="title" :title="title">
      <span>{{ title }}</span>
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section',
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
// 背景透明度
$bgOpacity: 0.9;

// 边框宽度
$borderSize: 1px;

// 边框颜色
$borderColor: #2b5a7d;

// 边框透明度
$borderOpacity: 0.4;

// 圆角
$borderRadius: 8px;

// 装饰圆度尺寸
$adornHeight: 10px;
$adornWidth: 120px;

.sc-dv-data-section {
  background-color: fade-out(#000, $bgOpacity);
  border: $borderSize fade-out($borderColor, $borderOpacity) solid;
  border-radius: 0 $borderRadius 0 $borderRadius;
  color: #fff;
  min-height: 48px;
  padding: 10px;
  position: relative;

  &::before,
  &::after {
    background-color: fade-out(#000, $bgOpacity);
    border: $borderSize fade-out($borderColor, $borderOpacity) solid;
    content: '';
    position: absolute;
    height: $adornHeight;
    width: $adornWidth;
  }

  &::before {
    border-radius: $borderRadius $borderRadius * 2 0 0;
    border-bottom: none;
    left: -$borderSize;
    top: -($adornHeight + $borderSize);
  }

  &::after {
    border-radius: 0 0 $borderRadius $borderRadius * 2;
    border-top: none;
    right: -$borderSize;
    bottom: -($adornHeight + $borderSize);
  }

  // 标题
  .title {
    line-height: 0;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0 16px;
    position: absolute;

    &::before {
      content: '';
      background-color: #66ffdb;
      background-image: linear-gradient(270deg, #66ffdb 0%, #7acbff 100%);
      box-shadow: 1px 0 4px 0 rgba(0, 255, 245, 0.59);
      border-radius: 0 4px 4px 0;
      display: block;
      height: 28px;
      left: -1px;
      position: absolute;
      top: 50%;
      margin-top: -14px;
      width: 4px;
    }

    span {
      font-size: 1rem;
      line-height: 48px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // 内容
  .body {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
}
</style>
