import { GetApplyRegion } from "../../../api/Reception2020";

// import TimerTask from '@/utils/timerTask';

const echarts = window.echarts;

// 地区进件
// 雷达图
export default {
  data() {
    return {
      applyRegionPending: false, // 加载指示
      applyRegionTask: null, // 定时刷新任务
      applyRegionTaskDuration: 1000 * 60 * 10, // 定时任务执行间隔
      applyRegion: [],
      applyRegionChart: null,
      applyRegionChartResize: null
    };
  },
  methods: {
    // 加载区域融资余额
    async loadApplyRegion() {
      try {
        this.applyRegionPending = true;
        this.applyRegion = await GetApplyRegion();
        this.$nextTick(() => {
          this.initApplyRegionChart(this.parseApplyRegionChartOptions());
        });
        // setTimeout(() => {
        //   this.initApplyRegionChart(this.parseApplyRegionChartOptions());
        // }, 100);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.applyRegionPending = false;
      }
    },

    // 构造图表数据
    parseApplyRegionChartOptions() {
      const { applyRegion } = this;

      // 无论后端返回数据格式如何，固定雷达图节点的位置
      const REGION_SET = ["华北", "西北", "西南", "华南", "华东", "华中"];

      const max = Math.max(
        ...[...applyRegion].map((row) => parseFloat(row.balance))
      );

      // 按预置顺序整理数据
      const _data = [...REGION_SET].map((name) => {
        const _row = applyRegion.find((set) => set.region === name);
        return {
          name: name,
          value: parseFloat(_row.balance),
          max
        };
      });

      return {
        radar: {
          radius: "50%",
          name: {
            textStyle: {
              color: "#fff",
              fontSize: 12,
              fontWeight: "bold"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#898EBB",
              width: 1
            }
          },
          splitNumber: 3,
          splitLine: {
            lineStyle: {
              color: "#898EBB",
              width: 1
            }
          },
          splitArea: {
            areaStyle: {
              color: ["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, .1)"]
            }
          },
          indicator: _data
        },
        series: [
          {
            type: "radar",
            symbol: "circle",
            symbolSize: 4,
            itemStyle: {
              normal: {
                color: "rgba(255, 255, 255, 0.5)",
                borderColor: "#fff",
                borderWidth: 2
              }
            },
            lineStyle: {
              color: "#898EBB",
              borderColor: "#898EBB",
              width: 0
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#66FFDB"
                  },
                  {
                    offset: 1,
                    color: "#7ACBFF"
                  }
                ],
                false
              ),
              opacity: 0.9,
              shadowColor: "rgba(2, 125, 163, .3)",
              shadowOffsetX: 0,
              shadowOffsetY: 16,
              shadowBlur: 16
            },
            data: [
              {
                name: "区域融资余额",
                value: [..._data].map((row) => row.value)
              }
            ]
          }
        ]
      };
    },

    // 初始化图表
    initApplyRegionChart(options) {
      // 初始化图表
      if (this.$refs && this.$refs.applyRegionChart && !this.applyRegionChart) {
        this.applyRegionChart = echarts.init(this.$refs.applyRegionChart);
      }
      if (this.applyRegionChart) {
        // 装载图表参数
        this.applyRegionChart.setOption(options, true);

        // 事件监听
        this.startApplyRegionChartEvent();
      }
    },

    // 雷达图尺寸调整
    handleApplyRegionChartResize() {
      if (this.applyRegionChartResize) {
        this.applyRegionChartResize = clearTimeout(this.applyRegionChartResize);
      }
      this.applyRegionChartResize = setTimeout(() => {
        this.applyRegionChart.resize();
        this.applyRegionChartResize = clearTimeout(this.applyRegionChartResize);
      }, 100);
    },

    // 开始事件监听
    startApplyRegionChartEvent() {
      window.addEventListener("resize", this.handleApplyRegionChartResize);
    },

    // 移除事件监听
    cancelApplyRegionChartEvent() {
      window.removeEventListener("resize", this.handleApplyRegionChartResize);
    }
  },
  // mounted() {
  //   // this.applyRegionTask = new TimerTask({
  //   //   task: this.loadApplyRegion,
  //   //   duration: this.applyRegionTaskDuration,
  //   // });
  //   console.log("applyRegion - Mounted");
  //   this.loadApplyRegion();
  // },
  beforeDestroy() {
    this.applyRegionTask.dispose();
    this.cancelApplyRegionChartEvent();
  }
};
