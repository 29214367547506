<template>
  <div class="sc-dv-data sc-dv-counter">
    <div v-if="prefix" class="prefix">{{ prefix }}</div>
    <div class="number">
      <span v-for="(item, index) in renderData" :key="index">
        <i>{{ item }}</i>
      </span>
    </div>
    <div v-if="suffix" class="suffix">{{ suffix }}</div>
  </div>
</template>

<script>
export default {
  name: 'DataNumber',
  props: {
    // 数值
    value: [Number, String],
    // 前缀
    prefix: String,
    // 后缀
    suffix: String,
  },
  computed: {
    renderData: function () {
      const { value } = this.$props;
      return value.toString().split('');
    },
  },
};
</script>

<style lang="scss" scoped>
$void: 10px;
$cardRadius: 3px;

.sc-dv-counter {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  .prefix,
  .suffix {
    color: #fff;
    font-size: 0.8rem;
    padding: 0 8px;
  }

  .number {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    span {
      height: 32px;
      margin: 0 1px;
      position: relative;
      text-align: center;
      width: 24px;

      i {
        font-family: 'DIN Alternate Bold';
        color: #00fff5;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: bold;
        line-height: 32px;
        position: relative;
        z-index: 2;
      }

      &::before,
      &::after {
        content: '';
        height: 50%;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
      }

      &::before {
        background-image: linear-gradient(
          to bottom,
          #27224e,
          darken(#27224e, 5%)
        );
        border-radius: $cardRadius $cardRadius 0 0;
        top: -1px;
      }

      &::after {
        border-radius: 0 0 $cardRadius $cardRadius;
        background-image: linear-gradient(
          to bottom,
          #4b4579,
          lighten(#4b4579, 5%)
        );
        bottom: -1px;
      }
    }
  }
}
</style>
